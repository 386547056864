<style>
.active-loan {
  background: rgba(255, 182, 57, 0.207);
  /* box-shadow: 5px 10px 0 0 #000; */
}
</style>

<template>
  <div class="card-style mx-0 mt-5">
    <!-- <p v-if="!table_loading" class="font-bold fs-18 secodry-text-3 pb-3">
      {{ $lang.LOAN_HISTORY }}
    </p> -->

    <v-card flat>
      <p class="text-right mr-2 text-caption red--text">
        *Click on Loan ID to display its EMI Details
      </p>
      <v-card flat outlined>
        <v-data-table
          :disable-sort="true"
          :headers="headers"
          :items="loan_list"
          :items-per-page="$keys.PAGE_LENGTH"
          :loading="table_loading"
          hide-default-footer
        >
          <!-- <template v-slot:item.loan_status="{ item }">
            <span :class="item.loan_status.toLocaleLowerCase()">{{
              item.loan_status
            }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              outlined
              dense
              v-if="allowed_status.includes(item.loan_status)"
              color="#2F80ED"
              @click="getAgreement(item.loan_id)"
              >View agreement</v-btn
            >
          </template> -->

          <template v-slot:body="{ items }">
            <tbody v-if="items.length == 0" class="text-center">
              <tr>
                <td :colspan="headers.length">
                  <p class="text-center py-4 w-100">No data found</p>
                </td>
              </tr>
            </tbody>
            <tbody v-for="item in items" :key="item.loan_id">
              <tr
                @dblclick="viewLoanDetails(item.loan_id)"
                :class="item.loan_id == active_loan ? 'active-loan' : ''"
              >
                <td>
                  <p
                    @click="viewLoanDetails(item.loan_id)"
                    class="cursor-pointer text-decoration-underline primary_2--text"
                  >
                    {{ item.loan_id }}
                  </p>
                </td>
                <td>{{ item.alloted_nbfc }}</td>
                <td>{{ item.loan_amount }}</td>
                <td>{{ item.sanctioned_loan_amount }}</td>
                <td>
                  <span
                    v-if="item.loan_status"
                    :class="item.loan_status.toLocaleLowerCase()"
                    >{{ item.loan_status }}</span
                  >
                  <span v-else>-</span>
                </td>
                <td>{{ item.number_of_emi }}</td>
                <td>{{ item.created }}</td>
                <td>{{ item.loan_reject_reason }}</td>
                <td class="d-flex align-center">
                  <v-btn
                    outlined
                    dense
                    small
                    color="purple"
                    class="mr-2"
                    @click="openTrackDataDialog(item)"
                  >
                    <v-icon>mdi-table-account</v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    dense
                    small
                    v-if="allowed_status.includes(item.loan_status)"
                    color="#2F80ED"
                    @click="getAgreement(item.loan_id)"
                    >View agreement
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-pagination
        v-model="page_number"
        :length="total_page_count"
        :total-visible="7"
        class="custome-pagination"
        @input="getLoanList"
      ></v-pagination>
    </v-card>
    <LoanTrackData ref="loan_track_dialog" />
  </div>
</template>
<script>
export default {
  components: {
    LoanTrackData: () =>
      import("@/components/new-customer/dialog/LoanTrackData.vue"),
  },
  data() {
    return {
      loan__id: null,
      // active_loan: null,
      table_loading: false,
      page_number: 1,
      total_page_count: 1,
      loan_list: [],
      allowed_status: [
        "Sanctioned",
        "Completed",
        "Disbursed",
        "Money on the way",
        "Setup Auto Debit",
      ],
      headers: [
        {
          text: "Loan ID",
          align: "start",
          filterable: false,
          value: "loan_id",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "NBFC",
          align: "start",
          filterable: false,
          value: "alloted_nbfc",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Loan Amount",
          value: "loan_amount",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Sanctioned Amount",
          value: "sanctioned_loan_amount",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Status",
          value: "loan_status",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Tenure",
          value: "number_of_emi",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Date",
          value: "created",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Reason",
          value: "loan_reject_reason",
          class: "blue-grey lighten-4 font-weight-bold",
        },
        {
          text: "Action",
          value: "action",
          class: "blue-grey lighten-4 font-weight-bold",
        },
      ],
    };
  },
  mounted() {
    this.getLoanList();
  },
  // watch:{
  //   active_loan(value){
  //           // console.log("loan__id",value);
  //     this.$store.dispatch("new_customer/setLoanId", value);
  //   }
  // },
  computed: {
    active_loan: {
      get() {
        return this.$store.getters["new_customer/getLoanId"];
      },
      set(newValue) {
        // console.log("loan__id",newValue);
        return this.$store.dispatch("new_customer/setLoanId", newValue);
      },
    },
  },
  methods: {
    /* fetching the customer list form server*/
    getLoanList() {
      const self = this;
      self.table_loading = true;
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        customer_id: self.decrypt(this.$route.params.id),
        // loan_status: this.loan_status,
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          self.loan_list = response.data.loan_list;
          self.total_page_count = response.data.total_page_count;
          // console.log("loan___id",this.$store.getters["new_customer/getLoanId"]);
          // self.active_loan = this.$store.getters["new_customer/getLoanId"]
        }
      };
      const finallyHandler = () => {
        self.table_loading = false;
        // self.toggle_loading()
      };

      self.request_GET(
        self,
        self.$urls.LOAN_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getAgreement(loan_id) {
      window.open(
        `${this.$urls.BASE_URL}${this.$urls.GET_AGREEEMENT}?loan_id=${loan_id}`,
        "_blank"
      );
    },
    viewLoanDetails(loan_id) {
      this.active_loan = loan_id;
    },
    openTrackDataDialog(item) {
      console.log("openTrackDataDialog", item);
      this.$refs.loan_track_dialog.openLoanTrackDataDialog(item);
    },
  },
};
</script>
